import * as apiRoutes from "../config/apiRoutes";

export const generateToken = async (deviceName) =>  {
    const reqHeaders = new Headers();
    reqHeaders.append("Accept", "application/json");

    const formdata = new FormData();
    formdata.append("nickname", deviceName);
    formdata.append("scope", 'ePOS');

    const requestOptions = {
        method: 'POST',
        headers: reqHeaders,
        body: formdata,
        redirect: 'follow'
    };
    //console.log(formdata);

    let token = false;

    await fetch(apiRoutes.requestAccess , requestOptions)
        .then(response => response.json())
        .then(async json => {
            //console.log(json);
            window.localStorage.setItem('AccessToken', json.token);
            token = json.token;

        })
        .catch(error => {
            console.log(error);
            console.log('General Error, please try again');
            token =  {
                error : error
            }
        });

    return token;

}

export const getToken = async (deviceName) =>  {
    const result  = window.localStorage.getItem('AccessToken');

    if(result) {
       // console.log('token found saving to state');
        return result;
    } else {
        console.log('no token found req. from server for ' + deviceName);
        return await generateToken(deviceName);
    }
}


export const checkAccess = async (accessToken)  => {
    console.log('-- Checking Access --' + new Date().toLocaleTimeString());
    console.log('- Starting check --' + new Date().toLocaleTimeString());
    //console.log(accessToken);
    const checkTime = new Date();
    const reqHeaders = new Headers();
    reqHeaders.append("Accept", "application/json");
    reqHeaders.append("Authorization", accessToken);
    reqHeaders.append("scope", apiRoutes.SCOPE);

    const requestOptions = {
        method: 'GET',
        headers: reqHeaders,
        redirect: 'follow'
    };

    let accessGranted = false;

    let query = await fetch(apiRoutes.checkAccess, requestOptions)
        .then((response) => {
            //console.log(response)
            //console.log('- check fetch complete --' + new Date().toLocaleTimeString());
            if (response.ok) {
                //console.log('-- Success --' + new Date().toLocaleTimeString());
                accessGranted = true
            } else {
                console.log('-- Not Authorised --' + new Date().toLocaleTimeString());
            }
        })
        .catch((error) => {
            console.log('error', error)

            accessGranted =  {
                error : error
            }

        });
    return accessGranted;
}


