import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

export default function AlertDialog({buttonContent, dialogTitle, dialogContent, dialogAction, buttonColor, iconButton, iconColor, buttonStyle}) {
    const [open, setOpen] = React.useState(false);
    iconButton = iconButton ?? false;
    buttonColor = buttonColor ?? 'primary';
    buttonStyle = buttonStyle ?? null;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (dialogAction !== undefined && typeof dialogAction === 'function') {
            dialogAction();
        }
    };

    return (
        <React.Fragment>
            {iconButton ?  <IconButton  onClick={handleClickOpen} color={buttonColor} sx={buttonStyle} >{buttonContent}</IconButton> :  <Button sx={buttonStyle}  color={buttonColor} variant="contained" onClick={handleClickOpen}>
                <Typography>{buttonContent}</Typography>
            </Button>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={"error"}>Cancel</Button>
                    <Button onClick={handleClose} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}