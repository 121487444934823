import {useEffect, useState} from "react";
import * as React from "react";
import {useDojo, useDojoList, useDojoUpdater} from "../context/dojoContext";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from "@mui/material/Alert";

export default function DojoTerminalErrorNotifcation() {
    const dojoTerminalID = useDojo();
    if(!dojoTerminalID) {
        return (<Alert mt={3} severity="error">{'Card Terminal has not been selected'}</Alert>)
    }
    return null;
}
