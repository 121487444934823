export const SCOPE = 'ePOS';
export const host = 'https://bookings.plymouthboattrips.co.uk/api/'
//export const host = 'http://localhost:8765/api/'

export const checkAccess = host + 'checkAccess';
export const requestAccess =  host + 'requestAccess';
export const getBoats = host + 'getBoats';
export const getLastEntry = host +  'getLastEntry';

//export const submitPassengerData  = host +  'submitPassengerData';

export const getTrips = host + 'getTrips';
export const getTripsByDay = host + 'getTripsByDate';
export const getBookings = host + 'getBookings';
export const getTripBookings = host + 'getTripBookings?';
export const getTripManifest = host + 'getTripManifest';
export const sendBookingUpdates = host + 'syncBookings';
export const checkBooking = host + 'checkBooking';
export const boardBooking = host + 'boardPassengers';
export const checkPrepaidCrossing = host + 'checkPrepaidCrossing';
export const boardWallet = host + 'boardWallet';

/* EPOS ROUTES */

export const getTillUser = host + 'getTillUser';
export const getDays = host + 'eposGetDays';
export const getDay = host + 'eposGetDay';
export const getTrip = host + 'eposGetTrip';
export const createBooking = host + 'eposCreateBooking';
export const cancelBooking = host + 'eposCancelBooking';
export const updateBooking = host + 'eposUpdateBooking';
export const completeBooking = host + 'eposCompleteBooking';


export const getCustomRates = host + 'getCustomRates';
export const getCoachDriverList = host + 'getCoachDriverList';
export const getDiscountsList = host + 'eposGetDiscountList';

export const checkGiftVoucher = host + 'checkGiftVoucher';
export const getCoachDriverPayoutData = host + 'getCoachDriverPayoutData';
export const submitCoachDriverPayoutRequest = host + 'submitCoachDriverPayoutRequest';

export const getAvailableGiftVouchers = host + 'getGiftVouchers';
export const generateGiftVoucher = host + 'generateGiftVoucher';
export const issueRefund = host + 'issueRefund';
export const getPreBookedBookings = host + 'eposGetPayableBookings';
export const getSubmitPreBookedBooking = host + 'eposSubmitBookingPayment';