import {createContext, useEffect, useState, useContext} from "react";

export const dojoUrl = "https://SP241152TGBLoc1.connect.paymentsense.cloud/";

export const apiKey = "a5ffdadf-b1d0-4feb-9329-796323e5e834";
export const softwareHouse = "SJ750W66";
export const installerID = "SJ750W66";
export const username = 'pbtEposSystem';
export const password = apiKey;
export const authToken = () => {
    return btoa(username + ':' + apiKey);
}

const DojoTerminalContext = createContext(null)
const DojoTerminalUpdater = createContext(null)
const DojoTerminalList = createContext(null)


export function DojoProvider ({children}) {
    const [terminalID, setTerminalID] = useState(false);

    useEffect(() => {
        setTerminalID(getTerminal())
    }, []);

    const getTerminal =  () => {
        return window.localStorage.getItem('terminalID') ?? false;
    }
    const setTerminal = (ID) => {
        setTerminalID(ID);
        window.localStorage.setItem('terminalID', ID);
    }

    const fetchTerminals = async () => {
        //console.log('getting Terminals');
        let myHeaders = new Headers();
        myHeaders.append("Software-House-Id", softwareHouse);
        myHeaders.append("Installer-Id", installerID);
        myHeaders.append("Authorization", "Basic " + authToken() );
        myHeaders.append("accept", "application/connect.v2+json" );

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const requestParams = new URLSearchParams({
            currency: 'GBP',
        });
        let terminals = [];

        await fetch(dojoUrl + "pac/terminals?", requestOptions)
            .then(response => {
                return response.json()
            })
            .then(result => {
                // console.log(result);
                terminals =  result.terminals
            })
            .catch(error => console.log('error', error));

        return terminals;
    }

    return (
        <DojoTerminalContext.Provider value={terminalID}>
            <DojoTerminalUpdater.Provider value={setTerminal}>
                <DojoTerminalList.Provider value={fetchTerminals}>
                    {children}
                </DojoTerminalList.Provider>
            </DojoTerminalUpdater.Provider>
        </DojoTerminalContext.Provider>
    );
}

export const useDojo = () => {
    return useContext(DojoTerminalContext);
}
export const useDojoUpdater = () => {
    return useContext(DojoTerminalUpdater);
}

export const useDojoList = () => {
    return useContext(DojoTerminalList);
}



export const sendSale = async ({amount, terminalID}) =>
{
    let reqHeaders = new Headers();
    reqHeaders.append("Software-House-Id", softwareHouse);
    reqHeaders.append("Installer-Id", installerID);
    reqHeaders.append("Authorization", "Basic " + authToken() );


    reqHeaders.append("accept", "application/connect.v2+json" );
    let bodyContent = {
        transactionType : 'SALE',
        currency : 'GBP',
        amount :  parseInt(amount*100)
    }

    let requestOptions = {
        method: 'POST',
        headers: reqHeaders,
        redirect: 'follow',
        body: JSON.stringify(bodyContent)
    };

    let responseData ={
        status : {
            ok : false,
            text: false,
            code: false,
        },
        data : {}
    };

    await fetch(dojoUrl + "pac/terminals/" + terminalID + '/transactions', requestOptions)
        .then(response => {
            responseData.status.ok = response.ok;
            responseData.status.code = response.status;
            responseData.status.text = response.statusText;
            return response
        })
        .then(response => response.json())
        .then(result => {
            responseData.data = result;
        })
        .catch(error => console.log('error', error));

    return responseData;
}
export const sendRefund = async ({amount, terminalID}) =>
{
    let reqHeaders = new Headers();
    reqHeaders.append("Software-House-Id", softwareHouse);
    reqHeaders.append("Installer-Id", installerID);
    reqHeaders.append("Authorization", "Basic " + authToken() );


    reqHeaders.append("accept", "application/connect.v2+json" );
    let bodyContent = {
        transactionType : 'REFUND',
        currency : 'GBP',
        amount :  parseInt(amount*100)
    }

    let requestOptions = {
        method: 'POST',
        headers: reqHeaders,
        redirect: 'follow',
        body: JSON.stringify(bodyContent)
    };

    let responseData ={
        status : {
            ok : false,
            text: false,
            code: false,
        },
        data : {}
    };

    await fetch(dojoUrl + "pac/terminals/" + terminalID + '/transactions', requestOptions)
        .then(response => {
            responseData.status.ok = response.ok;
            responseData.status.code = response.status;
            responseData.status.text = response.statusText;
            return response
        })
        .then(response => response.json())
        .then(result => {
            responseData.data = result;
        })
        .catch(error => console.log('error', error));

    return responseData;
}

export const signatureRequest = async ({location, terminalID, requestID, accepted}) =>
{
    console.log('sending signature response')
    let reqHeaders = new Headers();
    reqHeaders.append("Software-House-Id", softwareHouse);
    reqHeaders.append("Installer-Id", installerID);
    reqHeaders.append("Authorization", "Basic " + authToken() );

    let bodyContent = {
        'accepted' : accepted,
    };

    reqHeaders.append("accept", "application/connect.v2+json" );

    let requestOptions = {
        method: 'PUT',
        headers: reqHeaders,
        redirect: 'follow',
        body: JSON.stringify(bodyContent)
    };

    let responseData ={
        status : {
            ok : false,
        },
        data : {},
        error : false,
    };

    let reqUrl = location ?? dojoUrl + "pac/terminals/" + terminalID + '/transactions/' + requestID;
    reqUrl += '/signature';


    await fetch(reqUrl, requestOptions)
        .then(response => {
            responseData.status.ok = response.ok;
            responseData.status.code = response.status;
            responseData.status.text = response.statusText;
            return response
        })
        .then(response => {
            try {
                return response.json()
            } catch(e) {
                // more than likely an empty response
            }
            return {};
        })
        .then(result => {
            responseData.data = result;
        })
        .catch(error => {
            console.log('error', error)
            responseData.error = error;
        });

    return responseData;
}
export const getStatus = async ({location, terminalID, requestID}) =>
{
    console.log('fetching Status')
    let reqHeaders = new Headers();
    reqHeaders.append("Software-House-Id", softwareHouse);
    reqHeaders.append("Installer-Id", installerID);
    reqHeaders.append("Authorization", "Basic " + authToken() );


    reqHeaders.append("accept", "application/connect.v2+json" );

    let requestOptions = {
        method: 'GET',
        headers: reqHeaders,
        redirect: 'follow',
    };

    let responseData ={
        status : {
            ok : false,
        },
        data : {},
        error : false,
    };

    let reqUrl = location ?? dojoUrl + "pac/terminals/" + terminalID + '/transactions/' + requestID;

    await fetch(reqUrl, requestOptions)
        .then(response => {
            responseData.status.ok = response.ok;
            responseData.status.code = response.status;
            responseData.status.text = response.statusText;
            return response
        })
        .then(response => response.json())
        .then(result => {
            responseData.data = result;
        })
        .catch(error => {
            console.log('error', error)
            responseData.error = error;
        });

    return responseData;
}