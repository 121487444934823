import logo from './logo.svg';


import './App.css';
import {useEffect, useRef, useState} from "react";
import {ApiProvider} from "./context/apiContext";
import { generate, count } from "random-words";
import * as accessTokenActions from "./api/accessToken";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {Dashboard, EposScreen} from "./views/Dashboard";
import Alert from "@mui/material/Alert";
import {DojoProvider, useDojo} from "./context/dojoContext";



export default function App() {
    const [accessToken, setAccessToken] = useState(false)
    const [accessGranted, updateAccessStatus] = useState(null)
    const [userAction, setUserAction] = useState(null)
    const [deviceNickname, setDeviceNickname ] = useState('...');
    const [errorMessage, setErrorMessage ] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const timerLength = 10;
    const retryTimer = useRef(null);
    const checkInProgress = useRef(false);
    const accessTokenRef = useRef(false);

    useEffect(() => {
        setDeviceName();
    }, []);

    useEffect(() => {
        if(!accessGranted) {
            if(retryTimer.current == null) {
                startTimer({timeRemaining: -1, callbackFn:  checkAccess, start: timerLength});
            }
        } else  {
            clearTimeout(retryTimer.current);
        }
    }, [accessGranted]);

    function setDeviceName () {
        let lsNickname = window.localStorage.getItem('deviceNickname');

        if(lsNickname === null || !lsNickname.length) {
            lsNickname = 'ePOS ' + generate({minLength:5, exactly: 3, join: " " });
            window.localStorage.setItem('deviceNickname', lsNickname);
        }

        setDeviceNickname(lsNickname);
        return lsNickname;
    }

    function clearToken() {
        //console.log('Clearing Tokens');
        setAccessToken(false);
        updateAccessStatus(false);
        setUserAction('Resetting Token')
        window.localStorage.removeItem('AccessToken')
        window.localStorage.removeItem('deviceNickname')
        window.location.reload(false);
    }

    async function  checkAccess()  {
        if(checkInProgress.current) {
            return false;
        }
        checkInProgress.current = true;
        let nickname = setDeviceName();
        let newAccessToken;
        setErrorMessage(false)
        if(!accessTokenRef.current) {
            newAccessToken = await accessTokenActions.getToken(nickname);

            if(typeof newAccessToken  === 'object') {

                if(newAccessToken.error) {
                    setErrorMessage('Could not initialize access control : ' + newAccessToken.error.toString())
                }
                return false;
            }

            accessTokenRef.current = newAccessToken;

        }

        let checkResult = await accessTokenActions.checkAccess(accessTokenRef.current)
        checkInProgress.current = false;
        if(typeof checkResult  === 'boolean') {

            if(!checkResult && nickname) {
                setUserAction('Please grant access to ' + nickname);
            } else {
                setUserAction('Checking Device Access');
            }

            updateAccessStatus(checkResult);
            return checkResult;
        }

        if(checkResult.error) {
            setErrorMessage('Could not verify device : ' + checkResult.error.toString())
        }

        return false
    }

    async function startTimer({timeRemaining, callbackFn, start}) {
        clearTimeout(retryTimer.current);
        if (timeRemaining <= 0) {
            setCountDown(0);
            let result = await callbackFn();

            if (!result) {
                setCountDown(start);
                timeRemaining = start;
                setCountDown(start);
            } else  {
                clearTimeout(retryTimer.current);
                return;
            }
        } else {
            setCountDown(timeRemaining);
        }

        retryTimer.current = setTimeout(() => {
            timeRemaining--
            startTimer({timeRemaining, callbackFn, start});
        }, 1000)
    }

    function triggerCheck () {
        startTimer({timeRemaining: -1, callbackFn:  checkAccess, start: timerLength});
    }

    if(!accessGranted) {
        return (
            <ApiProvider>
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo"/>
                        <p>{accessGranted === null ? 'Please wait' :''}</p>
                        <p>{userAction}</p>

                        <p>Retrying in {countDown.toString() ?? '-'} seconds</p>
                        <Stack direction="column" alignItems={'center'} spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={triggerCheck} > Retry</Button>
                                <Button onClick={clearToken} variant="contained">Clear Token</Button>
                            </Stack>
                            {errorMessage ? (<Alert mt={3} severity="error">{errorMessage}</Alert>) : null}
                        </Stack>


                    </header>

                </div>
            </ApiProvider>
        );
    } else {

        return (
            <DojoProvider>
                <EposScreen accessToken={accessTokenRef.current} clearToken={clearToken}></EposScreen>
            </DojoProvider>
        );
    }
}


