import {createContext, useState} from "react";


const ApiTokenContext = createContext(null)
const ApiTokenDispatchContext = createContext(null)
const ApiAccessGranted = createContext(false);
const ApiAccessGrantedUpdater = createContext(null);

export function ApiProvider ({children}) {
    const [accessGranted, setAccessGranted] = useState(false);
    const token = window.localStorage.getItem('accessToken');
    const setToken = (value) => {
        if(value.length == 0) {

        }
        window.localStorage.setItem('accessToken', value);
    }
    return (
        <ApiTokenContext.Provider value={token}>
            <ApiTokenDispatchContext.Provider value={setToken}>
                <ApiAccessGranted.Provider value={accessGranted}>
                    <ApiAccessGrantedUpdater.Provider value={setAccessGranted}>
                        {children}
                    </ApiAccessGrantedUpdater.Provider>
                </ApiAccessGranted.Provider>
            </ApiTokenDispatchContext.Provider>
        </ApiTokenContext.Provider>
    );
}

