import {useEffect, useState} from "react";
import * as React from "react";
import {useDojo, useDojoList, useDojoUpdater} from "../context/dojoContext";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function DojoTerminalSelector() {
    const dojoTerminalID = useDojo();
    const dojoTerminalList = useDojoList();
    const dojoTerminalUpdater = useDojoUpdater();
    const [modalOpen, setOpenModal] = useState(false);
    const [availableTerminals, setAvailableTerminals] = useState([]);
    const [currentTerminal, setCurrentTerminalData] = useState([]);
    const [error, setError] = useState();
    useEffect(() => {
        getTerminals();
    }, []);
    useEffect(() => {
        if(modalOpen) {
            getTerminals();
        }
    }, [modalOpen]);

    const getTerminals=async () => {
        setAvailableTerminals(false);
        const data = await dojoTerminalList();
        if(data.length > 0 ) {
            setAvailableTerminals(data);
        } else {
            setError('No terminals returned');
        }
    }
    const selectTerminal = (terminal) => {
        dojoTerminalUpdater(terminal.tid)
        setOpenModal(false)
    }

    return (
        <>
            <Button variant={dojoTerminalID ? 'outlined' : 'contained'} color={dojoTerminalID ? "primary" : "error"} onClick={() => {
                setOpenModal(true);
            }}><FontAwesomeIcon  icon={'fa-credit-card'} style={{marginRight:10}}/>
                {dojoTerminalID ? (dojoTerminalID.substring(0,5) + '...') :  'Not Selected'}</Button>

            <Dialog onClose={() => setOpenModal(false)} open={modalOpen}>
                <DialogTitle>Select Terminal</DialogTitle>
                {availableTerminals === false ?
                    (  <Box  sx={{

                            width: 300,
                            height: 100,
                        }} >
                            <LinearProgress />
                            <Typography align={"center"} my={2}>Loading Terminals</Typography>

                        </Box>
                    )
                    : (
                        <List sx={{ pt: 0 }}>
                            {availableTerminals.map((terminal) => (
                                <ListItem disableGutters key={terminal.tid}>
                                    <ListItemButton
                                        selected={terminal.tid === dojoTerminalID }
                                        onClick={() => selectTerminal(terminal)}
                                        xs={{alignItems :'space-between'}}
                                        style={{gap: 20}}
                                    >
                                        <ListItemText primary={terminal.id} />
                                        <ListItemText primary={terminal.tid} />
                                        <ListItemText primary={terminal.status} />

                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
            </Dialog>
        </>

    )
}
